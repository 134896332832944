export const pages = [
  {
    title: 'Home',
    to: '/'
  },
  {
    title: 'Features',
    to: '/features'
  },
  {
    title: 'Register Your Interest',
    to: '/register_interest'
  },
  {
    title: 'Support',
    to: '/support'
  },
]
