import React from "react";
import {Controller} from "react-hook-form";
import {isEmpty} from "lodash";
import {isValidPhoneNumber} from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";

const PhoneInputFesto = ({item, control, setValue, errors}) => {

  const validatePhone = async (value) => {
    return isValidPhoneNumber(`+${value}`);
  };

  return (
    <div
      className={'input-container'}
    >
      <Controller
        name={item}
        control={control}
        rules={{
          validate: validatePhone,
        }}
        render={({ field }) => (
          <PhoneInput
            {...field}
            inputProps={{
              name: item,
              required: true,
              autoFocus: true,
            }}

            inputClass={errors[item] && 'input-error'}

            countryCodeEditable
            copyNumbersOnly
            onlyCountries={['us', 'gb', 'ua', 'pl', 'ae']}
            onChange={(phone, code) => {
              setValue('country_code', code?.dialCode)
              setValue(item, phone)
            }}
          />
        )}
      />


      { errors[item] && (
        <span className={'error'}>
          { isEmpty(errors[item]?.message)
            ? `Phone is invalid`
            : errors[item]?.message
          }
        </span>
      )}
    </div>
  )
}

export default PhoneInputFesto;
