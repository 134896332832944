import React from "react";

import iconChecked from "../../assets/svg/checked.svg";
import iconCheck from "../../assets/svg/check.svg";

const CheckBox = ({term, index, list, setList}) => {

  return (
    <div
      className="custom-checkbox"
      key={term?.id}
    >
      <div>
        <img
          src={term?.checked ? iconChecked : iconCheck}
          onClick={() => {
            const tempArr = [...list];
            tempArr.splice(index, 1, { ...term, checked: !term.checked });
            setList(tempArr);
          }}
        />

        <div className={'label'}>
          I accept the following terms:
          <span className={'description'}>
              {` ${term?.text}`}
            </span>
        </div>
      </div>
    </div>
  )
}

export default CheckBox;
