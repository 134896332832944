import React from "react";
import {Link} from "react-router-dom";

import public_private_events from "../../../assets/images/public_private_events.png";
import public_private_events_mobile from "../../../assets/images/public_private_events_mobile.png";

const HomePublicPrivate = () => {
  return (
    <div className={'public-private-container'}>
      <div>
        <img
          className={'image-desktop'}
          src={public_private_events}
        />

        <img
          className={'image-mobile'}
          src={public_private_events_mobile}
        />
      </div>

      <div>
        <h3>
          Plan Public/ Private Events
        </h3>

        <p>
          Chose whether to have your events for public or keep them private.
        </p>

        <Link
          to={process.env.REACT_APP_IOS_LINK}
          target="_blank"
        >
          Check out our App
        </Link>
      </div>
    </div>
  )
}

export default HomePublicPrivate;
