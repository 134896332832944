import React from "react";
import {Link} from "react-router-dom";
import {QRCodeSVG} from "qrcode.react";

const BuySuccess = ({qrCode}) => {
  return (
    <div className={'status-container'}>
      <div className={'status'}>
        The payment was <span>successful!</span>
      </div>

      <div>
        <p>
          This is your ticket.
        </p>
      </div>

      <div className={'qr-code'}>
        <QRCodeSVG
          value={qrCode}
          fgColor={'#4A20F0'}
          bgColor={'rgba(255,255,255,0)'}
          size={180}
        />
      </div>

      <div>
        <p>
          Duplicate ticket and payment receipt has been sent to you method of contact.
        </p>

        <p>
          Thank you for choosing Festo!
        </p>
      </div>

      <div className={'button'}>
        <Link to={'/'} >
          Go Home
        </Link>
      </div>
    </div>
  )
}

export default BuySuccess;
