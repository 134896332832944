const { format } = require('date-fns');

const formatDateAndTime = (inputDateTime) => {
  const inputDate = new Date(inputDateTime);

  // Format the date part as "yyyy-MM-dd"
  const formattedDate = format(inputDate, 'yyyy-MM-dd');

  // Format the time part as "hh:mm a" (12-hour clock)
  const formattedTime = format(inputDate, 'hh:mm a');

  return `${formattedDate}, ${formattedTime}`;
}

export default formatDateAndTime;
