import React from 'react';
import Topic from "../utils/topic";

import '../../../styles/Articles.scss';

import logo_menu from "../../../assets/svg/logo_menu.svg";

const ArticleTerms = () => {

  return (
    <>
      <div className="articles">
        <div className={'ellipse-container'}>
          <div className={'ellipse-first'}/>
          <div className={'ellipse-second'}/>
          <div className={'ellipse-third'}/>
        </div>

        <div className={'information'}>
          <div className={'information-container'}>
            <div className={'logo'}>
              <img src={logo_menu}/>
            </div>

            <div>
              <h1>
                Terms and Conditions
              </h1>
            </div>

            <div className={'information-content'}>
              <Topic
                label={`1. ACCEPTING THESE TERMS`}
              >
                <p>
                  These terms and conditions and any other document and/or policy referenced herein make up our Terms. Please note that this is a legally binding document which symbolizes a contract between you and "Festoentertainment LTD". Please read these Terms carefully.
                </p>
              </Topic>

              <Topic
                label={`2. ACCESS`}
                list={[
                  'You have attained the age of majority;',
                  'Your use of the app will be for lawful reasons;',
                  'You shall not engage in any offensive, indecent or improper conduct while using the app;',
                  'You are not violating any laws by accessing the app;'
                ]}
              >
                <p>
                  As a user of our services, "Festoentertainment LTD" grants you a restricted, non-exclusive, and revocable license to visit the app and use it as per the following terms:
                </p>
              </Topic>

              <Topic
                label={`3. ACCOUNT`}
              >
                <p>
                  You are at liberty to open an account on our app although the same is mandatory (“Account”). Your account is for your individual use only, and you may not authorize others to use your account for any purpose. In creating your account, you certify that all information you provide is complete and accurate. You agree to update your information when required or requested, and you further agree not to use another person’s account without permission. You are responsible for maintaining the confidentiality of, and restricting access to, your account and password, and you agree to accept sole responsibility for all activities that occur under your account or password. You agree to contact us immediately of any breach of security or unauthorized use of your account or any violation of these Terms by others of which you are aware. You agree that "Festoentertainment LTD" shall have no liability for any losses, damages, liabilities, or expenses you may incur due to any unauthorized use of your account, and you agree to indemnify us and hold us harmless for any such unauthorized use.
                </p>
              </Topic>

              <Topic
                label={`4. THE APP IS A VENUE AND WE ARE NOT A PARTY TO ANY TRANSACTION BETWEEN PARTY THROWERS & PARTY ATTENDEES`}
              >
                <p>
                  4.1 "Festoentertainment LTD" urges all users to be responsible about their use of this app and any transaction entered because of the parties posted on the app.The app provides an on-line marketplace to allow party throwers and party attendees to meet (collectively the “users”).
                </p>

                <p>
                  4.2 "Festoentertainment LTD" is not a party to any transaction between the users.This is true even if the app allows you to purchase a party ticket as the app may facilitate booking a party, but we are not a party to any transaction between users.
                </p>
              </Topic>

              <Topic
                label={`5. IDENTITY VERIFICATION`}
              >
                <p>
                  User verification on the Internet is difficult and "Festoentertainment LTD" cannot, and does not assume any responsibility for, the confirmation of each user's purported identity. We encourage you to communicate directly with a member through the tools available on the app, though even this does not assure you of the identity of the person with which you are communicating. "Festoentertainment LTD" further encourages you to take other reasonable measures to assure yourself of the other person’s identity and relevant details.
                </p>
              </Topic>

              <Topic
                label={`6. LIMITED LICENSE TO USE THE APP`}
              >
                <p>
                  Users are granted a limited, revocable non-exclusive license to access the app and the content and services provided on the app solely for the purpose of advertising a party, searching for a party, purchasing tickets, all in accordance with the Terms. Any use of the app that is not for one of these purposes or otherwise in accordance with the Terms or as otherwise authorized by us in writing is expressly prohibited.
                </p>
              </Topic>

              <Topic
                label={`7. LINKS`}
              >
                <p>
                  Our services may provide links or references to third party sites that "Festoentertainment LTD" does not operate, and which will allow you to leave our services or open an additional browser connecting you to the third-party site. We have no responsibility for the content of such third-party sites and shall not be liable for any damages or injury arising from that content or your use, reliance on or access to such third-party sites. Any such links to third party sites are provided as merely a convenience to the users of the services, and such links do not imply endorsement by "Festoentertainment LTD" of such other third-party sites or the content contained therein. "Festoentertainment LTD" disclaims all liability regarding your access to such linked web sites, and access to any other websites linked to the services is at your own risk.
                </p>
              </Topic>

              <Topic
                label={`8. INDEMNIFICATION`}
              >
                <p>
                  You agree to indemnify, defend and hold harmless "Festoentertainment LTD" from any and all claims and demands made by any third party due to or arising out of: (a) your access to or use of the services; (b) your breach of these terms; (c) your violation of any law or the rights of a third party; (d) any dispute or issue between you and any third party; (e) any user materials you upload to, or otherwise make available through the services; (f) your willful misconduct; and (g) any other party’s access to or use of the services using your account and password.
                </p>
              </Topic>

              <Topic
                label={`9. NO WARRANTIES`}
              >
                <p>
                  "Festoentertainment LTD" provides the services “as is” with all faults and “as available” and the entire risk as to satisfactory quality, performance, accuracy, and efforts is with you. To the maximum extent permitted by applicable law, "Festoentertainment LTD" makes no representations, warranties, or conditions, express or implied. "Festoentertainment LTD" expressly disclaims any and all warranties or conditions, express, statutory and implied including without limitation: (a) warranties or conditions of merchantability, fitness for a particular purpose, workmanlike effort, accuracy, title, quiet enjoyment, no encumbrances, no liens and non-infringement; (b) warranties or conditions arising through course of dealing or usage of trade; and (c) warranties or conditions of uninterrupted or error-free access or use.
                </p>
              </Topic>

              <Topic
                label={`10. PROHIBITED USES`}
              >
                <p>
                  You may not use, or encourage, promote, facilitate, instruct or induce others to use, the app or services for any activities that violate any law, statute, ordinance or regulation; for any other illegal or fraudulent purpose or any purpose that is harmful to others; or to transmit, store, display, distribute or otherwise make available content that is illegal, fraudulent or harmful to others.
                </p>
              </Topic>

              <Topic
                label={`11. LIMITATIONS`}
              >
                <p>
                  In no event will "Festoentertainment LTD" be liable to you for any indirect, incidental, special, consequential or punitive damages (including damages for loss of profits, goodwill, or any other intangible loss) arising out of or relating to your access to or use of, or your inability to access or use, the app or any materials or content available through the app, whether based on warranty, contract, tort (including negligence), statute, or any other legal theory, and whether or not we have been informed of the possibility of damage.
                </p>
              </Topic>

              <Topic
                label={`12. ENTIRE AGREEMENT`}
              >
                <p>
                  This terms and conditions contain the entire agreement and understanding among the parties hereto with respect to the subject matter hereof, and supersedes all prior and contemporaneous agreements, understandings, inducements, and conditions, express or implied, oral or written, of any nature whatsoever with respect to the subject matter hereof.
                </p>
              </Topic>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleTerms;
