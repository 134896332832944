import React from 'react';
import FeaturesHeader from "./additional/header";
import FeaturesWhiteContainer from "./additional/WhiteContainer";

import '../../styles/Features.scss';

import features_1 from "../../assets/images/features_1.png"
import gifFeatures1 from "../../assets/gif/features-1.gif"
import gifFeatures2 from "../../assets/gif/features-2.gif"
import features_3 from "../../assets/images/features_3_v2.png"
import features_4 from "../../assets/images/features_4.png"
import iconLock from "../../assets/svg/lock.svg";
import iconUnLock from "../../assets/svg/unlock.svg";
import iconEdit from "../../assets/svg/edit.svg";
import iconQrCode from "../../assets/svg/qr_code.svg";
import iconUserAdd from "../../assets/svg/userAdd.svg";
import iconSearch from "../../assets/svg/search.svg";
import iconPeople from "../../assets/svg/people.svg";

import iconNotification from "../../assets/svg/notification.svg";
import iconSend from "../../assets/svg/send.svg";
import iconAlert from "../../assets/svg/alert.svg";
import iconChat from "../../assets/svg/chat.svg";
import iconBank from "../../assets/svg/iconBank.svg";
import iconSecure from "../../assets/svg/iconSecure.svg";
import iconTicket from "../../assets/svg/ticket.svg";
import app_store_button from "../../assets/svg/app_store_button.svg";
import {Link} from "react-router-dom";

const Features = () => {

  return (
    <>
      <div className="features">
        <FeaturesHeader/>

        <div className={'white-containers'}>
          <FeaturesWhiteContainer
            pic={features_1}
            topLabel={'Events'}
            title={'All your usual stuff...'}
            description={'Whether an event is public, private or In between, host them all on Festo!'}
            icons={[
              {
                icon: iconLock,
                text: 'Private Events'
              },
              {
                icon: iconUnLock,
                text: 'Public Events'
              },
              {
                icon: iconEdit,
                text: 'Create a Party'
              },
              {
                icon: iconQrCode,
                text: 'Scan QR codes'
              },
            ]}
          />

          <FeaturesWhiteContainer
            reverse
            blurVideo
            pic={gifFeatures1}
            picRound
            topLabel={'Friends'}
            title={'Get the piece of the pie!'}
            description={'Festo isn\'t just your regular event platform, connecting people through social media is what differentiates us from many other platforms.'}
            icons={[
              {
                icon: iconUserAdd,
                text: 'High user retention'
              },
              {
                icon: iconSearch,
                text: 'Expand your audience'
              },
              {
                icon: iconPeople,
                text: 'Establish community'
              },
            ]}
          />

          <FeaturesWhiteContainer
            pic={features_3}
            video={gifFeatures2}
            topLabel={'Communication'}
            title={'Chat directly in the app, post photos and tell your friends about your activities'}
            description={'Festo allows you to reach your audience directly through chats and stories!'}
            icons={[
              {
                icon: iconNotification,
                text: 'Notifications'
              },
              {
                icon: iconSend,
                text: 'Chat on App'
              },
              {
                icon: iconAlert,
                text: 'Stories'
              },
              {
                icon: iconChat,
                text: 'Comments'
              },
            ]}
          />

          <FeaturesWhiteContainer
            reverse
            blurVideo
            pic={features_4}
            topLabel={'Finance manager'}
            title={'Sell tickets and get paid right in the app!'}
            description={'Forget the hassle and let us handle YOU getting YOUR money!'}
            icons={[
              {
                icon: iconBank,
                text: 'Add Bank'
              },
              {
                icon: iconSecure,
                text: 'Secure Payments'
              },
              {
                icon: iconTicket,
                text: 'Promo code'
              },
            ]}
          />

          <Link
            to={process.env.REACT_APP_IOS_LINK}
            target="_blank"
            className={'logo_app_store'}
          >
            <img src={app_store_button}/>
          </Link>

          <div className={'ellipse left'}/>
          <div className={'ellipse right'}/>
        </div>
      </div>
    </>
  );
};

export default Features;
