import React from "react";
import {reviewsUser} from "../utils/reviewsUser";

import logo_menu from "../../../assets/svg/logo_menu.svg";

const HomeReviews = () => {
  return (
    <div className={'reviews-container'}>
      <div className={'labels'}>
        <p>
          Why people love it?
        </p>

        <img src={logo_menu}/>
      </div>

      <div className={'reviews-horizontal'}>
        {reviewsUser?.map(review => (
          <div className={'review-card'}>
            <div className={'review-user'}>
              <img src={review?.img}/>

              <p>
                {review?.name}
              </p>

              <span>
                {review?.city}
              </span>
            </div>

            <div className={'review-info'}>
              <h5>
                {review?.label}
              </h5>
              <p>
                {review?.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default HomeReviews;
