import {createContext, useState} from "react";

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const [modalData, setModalData] = useState(false);

  return (
    <ModalContext.Provider value={{ modalData, setModalData }}>
      {children}
    </ModalContext.Provider>
  );
}

export { ModalProvider, ModalContext };
