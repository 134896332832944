const requestApiFormData = async (url, options, method = 'POST', prefix) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();

    if(options){
      for (const [key, value] of Object.entries(options)) {
        if(key && value)
          formData.append(prefix ? `${prefix}[${key}]` : key, value);
      }
    }

    fetch(`${process.env.REACT_APP_BASE_URL}${url}`, {
      method: method,
      body: formData,
    })
      .then(response => response?.json())
      .then(req => resolve(req))
      .catch(err => reject(err))
  });
}

export default requestApiFormData;
