import Menu from "./components/Menu";
import { Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import Features from "./pages/Features";
import RegisterInterest from "./pages/RegisterInterest";
import ArticlePrivacy from "./pages/Articles/Privacy";
import ArticleTerms from "./pages/Articles/Terms";
import PartyView from "./pages/Party/View";
import SupportForm from "./pages/Support";
import SupportView from "./pages/Support/View";
import Footer from "./components/Footer";
import AcceptCookies from "./components/AcceptCookies";
import React, {useContext} from "react";
import {ModalContext} from "./utils/Providers/ModalProvider";
import ArticleUserDeletionFacebook from "./pages/Articles/UserDeletionFacebook";

const FestoApp = () => {
  const { modalData } = useContext(ModalContext);

  return (
    <div className={`app ${modalData ? 'not-scroll' : ''}`}>
      <Menu/>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/features" element={<Features />} />
        <Route path="/register_interest" element={<RegisterInterest />} />

        <Route path="/privacy" element={<ArticlePrivacy />} />
        <Route path="/terms" element={<ArticleTerms />} />
        <Route path="/facebook-account-delete" element={<ArticleUserDeletionFacebook />} />

        <Route path="/success" element={<PartyView success />} />
        <Route path="/party/:id" element={<PartyView />} />

        <Route path="/support" element={<SupportForm />} />
        <Route path="/support/:id" element={<SupportView />} />
      </Routes>

      <Footer/>

      <AcceptCookies/>
    </div>
  )
}

export default FestoApp;
