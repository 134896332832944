import iconRateFull from "../../../assets/svg/rate-purple.svg";
import iconRateEmpty from "../../../assets/svg/rate-silver.svg";
import React from "react";

const HostInfo = ({data}) => {
  return (
    <div className={'host-info'}>
      <img src={data?.host?.photo}/>

      <div>
        <p>
          {data?.host?.name}
        </p>

        <div>
          <p>Host</p>

          <div className={'rate-host'}>
            {[...Array(5)].map((_, index) => (
              <img key={index} src={((index < data?.avg_rating) || data?.avg_rating === 0) ? iconRateFull : iconRateEmpty} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HostInfo;
