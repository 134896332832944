import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from "./utils/ScrollToTop";
import {SnackbarProvider} from "notistack";
import {ModalProvider} from "./utils/Providers/ModalProvider";
import FestoApp from "./FestoApp";

import './styles/App.scss';

const App = () => {

  return (
    <Router>
      <ScrollToTop />

      <SnackbarProvider maxSnack={3}>
        <ModalProvider>
          <FestoApp/>
        </ModalProvider>
      </SnackbarProvider>
    </Router>
  );
};

export default App;
