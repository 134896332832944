import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useSnackbar} from "notistack";
import InputField from "../InputField";
import requestApiFormData from "../../utils/requestApiFormData";
import ShowAlert from "../ShowAlert";

import '../../styles/Subscribe.scss';

import subscribe_background from "../../assets/images/subscribe_background.png";
import mail_pic from "../../assets/images/mail_pic.png";


const Subscribe = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [load, setLoad] = useState(false)

  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: ''
    }
  });

  const onSubmit = async (data) => {
    requestApiFormData(
      '/user/create-subscribe',
      data,
    )
      .then(({data, status}) => {
        if(!status){
          ShowAlert('error', 'Unknown error!', enqueueSnackbar);
          return false;
        }

        ShowAlert('success', 'Your request has been created.', enqueueSnackbar);
        reset();
      })
      .catch((err) => {
        ShowAlert('error', 'Unknown error!', enqueueSnackbar);
      })
      .finally(() => {
        setLoad(false);
      })
  };

  return (
    <div
      className={'subscribe'}
      style={{backgroundImage: `url(${subscribe_background})`}}
    >
      <div className={'ellipses'}>
        <div className={'ellipse_left'}/>
        <div className={'ellipse_right'}/>
      </div>

      <div className={'subscribe-content'}>
        <div className={'mail-pic'}>
          <img src={mail_pic}/>
        </div>

        <div className={'mail-info'}>
          <h5>
            Subscribe to our newsletters
          </h5>

          <p>
            Enter your email address
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="input-container">
              <InputField
                className="email-input"
                name="email"
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Invalid email address',
                  },
                }}
                placeholder="name@example.com"
                errors={[]}
              />

              <button
                className="submit-button"
                disabled={load}
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Subscribe;
