import React from "react";
import {Link} from "react-router-dom";

import iconBank from "../../../assets/svg/iconBank.svg";
import iconSecure from "../../../assets/svg/iconSecure.svg";
import billing_example from "../../../assets/images/billing-example.png";

const HomeBilling = () => {
  return (
    <div className={'billing-container'}>
      <div>
        <h3>
          Convenient billing system all-in-app, you don't even need to go anywhere!
        </h3>

        <p>
          Create and get paid for events directly from the app.
        </p>

        <div className={'icons-container'}>
          <div>
            <img src={iconBank}/>

            <span>
                  Add bank
                </span>
          </div>

          <div>
            <img src={iconSecure}/>

            <span>
                  Secure payments
                </span>
          </div>
        </div>

        <Link
          to={process.env.REACT_APP_IOS_LINK}
          target="_blank"
        >
          Download Our App Now
        </Link>
      </div>

      <div>
        <div className={'ellipses'}>
          <div className={'ellipse'}/>
        </div>

        <img src={billing_example}/>
      </div>
    </div>
  )
}

export default HomeBilling;
