import React from "react";
import {Link} from "react-router-dom";
import HomeSubscribe from "../Subscribe";
import {pages} from "../Menu/pages";

import '../../styles/Footer.scss';

import logo_menu from "../../assets/svg/logo_menu.svg";
import app_store_button_black from "../../assets/svg/app_store_button_black.svg";
import iconInstagram from "../../assets/svg/socials/white/instagram.svg";
import iconFacebook from "../../assets/svg/socials/white/facebook.svg";

const Footer = () => {

  return (
    <div className={'footer'}>
      <HomeSubscribe/>

      <div className={'footer-container-first'}>
        <div className={'footer-menu'}>
          <ul>
            <li>
              <Link
                to={pages[0]?.to}
                className={'image'}
              >
                <img src={logo_menu}/>
              </Link>
            </li>

            {pages?.map((page) => (
              <li>
                <Link
                  to={page?.to}
                >
                  {page?.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className={'socials'}>
          <Link
            to={pages[0]?.to}
            className={'image logo'}
          >
            <img src={logo_menu}/>
          </Link>

          <Link
            to={process.env.REACT_APP_IOS_LINK}
            target="_blank"
            className={'image'}
          >
            <img src={app_store_button_black}/>
          </Link>

          <Link
            to={process.env.REACT_SOCIALS_INSTAGRAM}
            // target="_blank"
            className={'socialBtn'}
          >
            <img src={iconInstagram}/>
          </Link>

          <Link
            to={process.env.REACT_SOCIALS_FACEBOOK}
            // target="_blank"
            className={'socialBtn'}
          >
            <img src={iconFacebook}/>
          </Link>
        </div>
      </div>

      <div className={'footer-container-second'}>
        <Link>
          Festoentertainment LTD
        </Link>

        <Link
          to={'/privacy'}
        >
          Privacy Policy
        </Link>

        <Link
          to={'/terms'}
        >
          Terms and Conditions
        </Link>
      </div>
    </div>
  )
}

export default Footer;
