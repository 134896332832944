import React from "react";

const Members = ({ data }) => {
  return (
    <>
      <div className={'members'}>
        {data?.joining_user?.map(member => (
          <img src={member}/>
        ))}

        <div>
          {`${data?.joining_user_count}+`}
        </div>
      </div>
    </>
  )
}

export default Members;
