import React, {useEffect, useState} from 'react';
import {Link, useParams, useSearchParams} from "react-router-dom";
import {isArray, isEmpty} from "lodash";
import requestApi from "../../../utils/requestApi";
import Gallery from "../../../components/Gallery";
import GoogleMap from "../../../components/GoogleMap";
import ModalBuy from "../../../components/Modal/Buy";
import Modal from "../../../components/Modal";
import HostInfo from "./HostInfo";
import Members from "./Members";
import Information from "./Information";
import Tickets from "./Tickets";
import BuySuccess from "../../../components/Modal/BuySuccess";

import '../../../styles/Party.scss';

import loader from "../../../assets/gif/loader.gif"

const PartyView = ({success}) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const [load, setLoad] = useState(true);
  const [modalTicket, setModalTicket] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [data, setData] = useState(null);
  const [hoverTicket, setHoverTicket] = useState(0);
  const [qrCode, setQrCode] = useState(null);

  const ticketData = data?.tickets?.find(ticket => ticket?.id === hoverTicket) || null

  const loadInfoParty = () => {
    requestApi.get(`/party/party-details?id=${id}`)
      .then(({data, status}) => {
        if(!status){
          setData(null)
          return null
        }

        setLoad(false)
        setData(data)

        isArray(data?.tickets) && setHoverTicket(data?.tickets[0]?.id)
      })
      .catch(() => {
        window.location.href = '/'
      })
  }

  useEffect(() => {
    if(!isEmpty(searchParams.get("token")) && id)
      setModalSuccess(true)
    // if(success && !isEmpty(searchParams.get("token")) && !id) {
    //   // requestApiFormData(`/payment/check-stripe`, {token: searchParams.get("token")})
    //   // navigate(`/party/${id}?token=${searchParams.get("token")}`)
    // }
    // else
    //   loadInfoParty()

    loadInfoParty()
  }, [])

  return load ? (
    <div className={'loader'}>
      <img src={loader}/>
    </div>
  ) : (
    <>
      <div className="party-view">
        <div className={'party-container'}>
          <div className={'purple-border-top'}/>
          <div className={'background-white'}>
            <div className={'left-container'}>
              <div className={'first-block'}>
                <HostInfo data={data}/>

                <Members data={data}/>
              </div>

              <h1 className={'title'}>
                {data?.title}
              </h1>

              <div className={'second-block'}>
                <Information data={data}/>

                <div className={'description'}>
                  <h5>
                    Description
                  </h5>

                  <div dangerouslySetInnerHTML={{ __html: data?.note }} />
                </div>
              </div>

              <Gallery
                photos={data?.party_images}
              />
            </div>

            <div className={'right-container'}>
              <Tickets
                data={data}
                hoverTicket={hoverTicket}
                setHoverTicket={setHoverTicket}
                setModalTicket={setModalTicket}
              />

              {!isEmpty(data?.location_lat) && !isEmpty(data?.location_lng) && (
                <GoogleMap
                  positions={{
                    lat: data?.location_lat,
                    lng: data?.location_lng
                  }}
                  zoom={18}
                  markerIconUrl={'/map-point.svg'}
                  markerImageUrl={data?.party_images[0]?.image_url?.replace(/\.(png|jpg|jpeg)$/, '_round.png')}
                  address={data?.location}
                />
              )}
            </div>
          </div>
        </div>

        <div className={'go-home'}>
          <Link to={'/'}>
            Go home
          </Link>
        </div>

        <Modal
          open={modalTicket}
          setOpen={setModalTicket}
          title={ticketData?.name}
        >
          <ModalBuy
            terms={data?.terms}
            ticketId={ticketData?.id}
            partyId={ticketData?.party_id}
            cost={ticketData?.price}
            currency={data?.currency?.badge}
          />
        </Modal>

        <Modal
          open={modalSuccess}
          setOpen={setModalSuccess}
          title={data?.title}
        >
          <BuySuccess qrCode={qrCode}/>
        </Modal>

      </div>
    </>
  );
};

export default PartyView;
