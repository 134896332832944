import React from "react";
import {Link} from "react-router-dom";

import registration_so_easy from "../../../assets/images/registration_so_easy.png";

const HomeRegistrationSoEasy = () => {
  return (
    <div className={'registration-so-easy-container'}>
      <div>
        <h3>
          Registration so easy - our competitors aren't sure its legal...
        </h3>

        <p>
          Only crucial information needed to start up your events
        </p>

        <Link
          to={process.env.REACT_APP_IOS_LINK}
          target="_blank"
        >
          Download Our App Now
        </Link>
      </div>

      <div>
        <div className={'ellipses'}>
          <div className={'ellipse'}/>
        </div>

        <img src={registration_so_easy}/>
      </div>
    </div>
  )
}

export default HomeRegistrationSoEasy;
