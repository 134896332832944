import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

const requestApi = {
  async get(url) {
    const response = await instance.get(url);

    return response.data;
  },

  async post(url, data) {
    const response = await instance.post(url, data);

    return response.data;
  },

  async patch(url, data) {
    const response = await instance.patch(url, data);

    return response.data;
  },

  async delete(url) {
    return instance.delete(url);
  },
};

export default requestApi;
