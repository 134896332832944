import React from "react";

const Tickets = (
  {
    data,
    hoverTicket,
    setHoverTicket,
    setModalTicket
  }
) => {
  const onMouseEnterTicket = (id) => {
    setHoverTicket(id)
  }

  return (
    <div className={'tickets-container'}>
      <p>
        Tickets
      </p>

      <div className={'tickets'}>
        {data?.tickets?.sort((a, b) => a.price - b.price)?.map((ticket) => (
          <div
            className={'ticket'}
            key={ticket?.id}
            onMouseEnter={() => onMouseEnterTicket(ticket?.id)}
          >
            <div className={'title'}>
              {ticket?.name}
            </div>

            <div className={'count'}>
              {ticket?.qty_now} tickets left
            </div>

            <div className={'price'}>
              {(ticket?.price === "0") ? `Free` : `${data?.currency?.badge}${ticket?.price}`}
            </div>

            {(hoverTicket === ticket?.id && !data?.is_expired) && (<div
              className={'button'}
              onClick={() => {

                setModalTicket(true)
              }}
            >
              Buy Now
            </div>)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Tickets;
