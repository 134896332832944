import React from 'react';
import HomeHeader from "./additional/header";
import HomeHeaderAfter from "./additional/headerAfter";
import HomeMeetFest from "./additional/meetFest";
import HomeSubscribe from "../../components/Subscribe";
import HomeRegistrationSoEasy from "./additional/registrationSoEasy";
import HomePublicPrivate from "./additional/publicPrivate";
import HomeBilling from "./additional/billing";
import HomeReviews from "./additional/reviews";

import '../../styles/Home.scss';

const Home = () => {

  return (
    <div className="home">
      <HomeHeader/>

      <HomeHeaderAfter/>

      <HomeMeetFest/>

      <div className={'subscribe-container'}>
        <HomeSubscribe/>
      </div>

      <HomeRegistrationSoEasy/>

      <HomePublicPrivate/>

      <HomeBilling/>

      <HomeReviews/>
    </div>
  );
};

export default Home;
