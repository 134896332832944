import React from "react";
import {Link} from "react-router-dom";

import logo_menu from "../../../assets/svg/logo_menu.svg";
import app_store_button from "../../../assets/svg/app_store_button.svg";

const HomeHeader = () => {
  return (
    <div className={'header'}>
      <div className={'color'}>
        <div className={'ellipse_first'}/>
        <div className={'ellipse_second'}/>
        <div className={'ellipse_third'}/>

        <div className={'information'}>
          <div className={'logo'}>
            <img src={logo_menu}/>
          </div>

          <div>
            <h1>
              Even your mom can host an event on Festo!
            </h1>
          </div>

          <div>
            <h2>
              Meet Festo - all-in-one ultimate solution to event hosting for anyone and everyone 🤗
            </h2>
          </div>


          <Link
            to={process.env.REACT_APP_IOS_LINK}
            target="_blank"
            className={'logo_app_store'}
          >
            <img src={app_store_button}/>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default HomeHeader;
