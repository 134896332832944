import user_w_1 from "../../../assets/images/users/user_w_1.png";
import user_w_2 from "../../../assets/images/users/user_w_2.png";
import user_w_3 from "../../../assets/images/users/user_w_3.png";
import user_m_1 from "../../../assets/images/users/user_m_1.png";
import user_m_2 from "../../../assets/images/users/user_m_2.png";
// import user_m_3 from "../../../assets/images/users/user_m_3.png";

export const reviewsUser = [
  {
    id: 1,
    name: 'Sarah Walker',
    img: user_w_1,
    city: 'London',
    label: 'Party Planning Made Fun!',
    description: 'As a busy mom, this app is a lifesaver. It\'s user-friendly and helps me throw fantastic parties effortlessly'
  },
  {
    id: 2,
    name: 'Michael Turner',
    img: user_m_1,
    city: 'New York',
    label: 'Must-Have Party App',
    description: 'I\'ve hosted countless events, and this app is by far the best. Invitations, RSVPs, and more – it\'s all here!'
  },
  {
    id: 3,
    name: 'Emily Green',
    img: user_w_2,
    city: 'Los Angeles',
    label: 'Party Invites Simplified',
    description: 'This app\'s invite management is a game-changer. I can focus on the fun part of hosting, not logistics'
  },
  {
    id: 4,
    name: 'James Harris',
    img: user_m_2,
    city: 'Manchester',
    label: 'Party Perfection Achieved!',
    description: 'This app turned my regular gatherings into unforgettable parties. It\'s an absolute must for hosts!'
  },
  {
    id: 5,
    name: 'Sophie Clark',
    img: user_w_3,
    city: 'Birmingham',
    label: 'Effortless Event Planning',
    description: 'Whether it\'s a small get-together or a big bash, this app ensures everything runs smoothly. Love it!'
  }
];
