const uploadFiles = async (files, token, messageId) => {
  const formData = new FormData();

  const promises = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    // Create a new Promise to append each file to the FormData object
    const promise = new Promise((resolve, reject) => {
      formData.append("files[]", file);
      resolve();
    });

    promises.push(promise);
  }

  // Append token and messageId to the FormData object
  formData.append("token", token);
  formData.append("message_id", messageId);

  // Use Promise.all() to wait for all Promises to complete before returning
  return Promise.all(promises).then(() => formData);
}

const handleImageUpload = (files, token, messageId) => {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/support/add-files`, {
      method: "POST",
      body: await uploadFiles(files, token, messageId),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
};

export default handleImageUpload;
