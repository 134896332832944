import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {pages} from "./pages";

import './../../styles/Menu.scss';

import logo_menu from "../../assets/svg/logo_menu.svg"
import app_store_button from "../../assets/svg/app_store_button.svg"
import menuMobile from "../../assets/svg/menuMobile.svg"
import menuClose from "../../assets/svg/menuClose.svg"
import app_store_button_black from "../../assets/svg/app_store_button_black.svg";
import iconInstagram from "../../assets/svg/socials/black/instagram.svg";
import iconFacebook from "../../assets/svg/socials/black/facebook.svg";

const Menu = ({}) => {
  const location = useLocation();
  const currentPagePath = location?.pathname;
  const isHome = (currentPagePath === '/')

  const mobileMenu = useRef(null);
  useOutsideMenuClick(mobileMenu);

  const [openMobile, setOpenMobile] = useState(false);

  const isActive = (page) => {
    const pageTo = page?.to;

    const removeNonAlphanumeric = (str) => {
      return str.replace(/[^a-zA-Z0-9_]/g, '');
    }

    const sanitizedPageTo = removeNonAlphanumeric(pageTo);

    return (sanitizedPageTo.length >= 1 && currentPagePath.includes(sanitizedPageTo))
      || currentPagePath === pageTo;
  }

  function useOutsideMenuClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target))
            setOpenMobile(false)
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    document.body.style.overflowY = openMobile ? 'hidden' : 'visible';
  }, [openMobile]);

  useEffect(() => {
    if(openMobile)
      setOpenMobile(false)
  }, [location])

  return (
    <>
      <div className={`menu-container`}>
        <div className={`menu-top ${isHome ? '' : 'white'}`}>
          <Link
            to={pages[0]?.to}
            className={'image logo-menu-top'}
          >
            <img src={logo_menu}/>
          </Link>

          <div className={'menu-mobile'}>
            <div className={'btn'} onClick={() => setOpenMobile(true)}>
              <img src={menuMobile}/>

              <span>
                Menu
              </span>
            </div>
          </div>

          <div className={`menu-top-container ${!isHome && 'white'}`}>
            <ul>
              {pages?.map((page) => (
                <li>
                  <Link
                    to={page?.to}
                    className={
                      isActive(page)
                        ? 'active'
                        : ''
                    }
                  >
                    {page?.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <Link
            to={process.env.REACT_APP_IOS_LINK}
            target="_blank"
            className={'image right'}
          >
            <img src={app_store_button}/>
          </Link>
        </div>
      </div>

      <div className={`menu-mobile-left ${openMobile ? 'show-animation-menu' : 'hide-animation-menu'}`} >
        <div
          ref={mobileMenu}
          className={'menu-mobile-container'}
        >
          <div className={'menu-mobile-header'}>
            <Link
              to={pages[0]?.to}
              className={'image logo-menu-top'}
            >
              <img src={logo_menu}/>
            </Link>

            <div className={'btn'} onClick={() => setOpenMobile(false)}>
              <span>
                Menu
              </span>

              <img src={menuClose}/>
            </div>
          </div>

          <div className={'menu-mobile-center'}>
            {pages?.map((page) => (
              <Link
                to={page?.to}
                className={
                  isActive(page)
                    ? 'active'
                    : ''
                }
              >
                {page?.title}
              </Link>
            ))}
          </div>

          <div className={'menu-mobile-bottom'}>
            <Link
              to={process.env.REACT_APP_IOS_LINK}
              target="_blank"
              className={'image'}
            >
              <img src={app_store_button_black}/>
            </Link>

            <Link
              to={process.env.REACT_SOCIALS_INSTAGRAM}
              // target="_blank"
              className={'socialBtn'}
            >
              <img src={iconInstagram}/>
            </Link>

            <Link
              to={process.env.REACT_SOCIALS_FACEBOOK}
              // target="_blank"
              className={'socialBtn'}
            >
              <img src={iconFacebook}/>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Menu;
