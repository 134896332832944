import InputField from "../../InputField";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useSnackbar} from "notistack";
import requestApiFormData from "../../../utils/requestApiFormData";
import ShowAlert from "../../ShowAlert";
import CheckBox from "../../CheckBox";
import {isEmpty} from "lodash";
import PhoneInputFesto from "../../InputField/PhoneInput";

const ModalBuy = (
  {
    cost,
    terms,
    currency,
    partyId,
    ticketId
  }
) => {
  const { enqueueSnackbar } = useSnackbar();

  const [load, setLoad] = useState(false);
  const [termsList, setTermsList] = useState([]);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      country_code: '',
      comment: '',
      company: ''
    }
  });

  useEffect(() => {
    const arr = [];

    Array.isArray(terms) && terms[0] && terms.map((term) => {
      arr.push({ id: term?.id, checked: false, text: term?.description })
    })

    setTermsList(arr);
  },[terms]);

  const onSubmit = async (data) => {
    data['party_id'] = partyId;
    data['ticket_id'] = ticketId;
    data['phone'] = data['phone']?.replace(data['country_code'], "")
    data['birth_date'] = new Date(data?.birth_date)?.getTime() / 1000

    requestApiFormData(
      '/payment/create-payment-from-site',
      data,
      'POST',
      'PaySiteForm'
    )
      .then(({message, status}) => {
        if(!status){
          ShowAlert('error', 'Unknown error!', enqueueSnackbar);
          return false;
        }

        if(message?.includes('https://checkout.stripe.com/')){
          ShowAlert('success', 'Your request has been created.', enqueueSnackbar);
          reset();

          setTimeout(() => window.location.href = message, 100)
        }
        else {
          ShowAlert('error', 'Unknown error!', enqueueSnackbar);
        }
      })
      .catch((err) => {
        ShowAlert('error', 'Unknown error!', enqueueSnackbar);
      })
      .finally(() => {
        setLoad(false);
      })
  }

  return (
    <div className={'custom-form'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'inputs-two'}>
          <InputField
            name="first_name"
            control={control}
            rules={{ required: true }}
            label="First name"
            placeholder="John"
            errors={errors}
          />

          <InputField
            name="last_name"
            control={control}
            rules={{ required: true }}
            label="Last name"
            placeholder="Smith"
            errors={errors}
          />
        </div>

        <div className={'inputs-two'}>
          <InputField
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address',
              },
            }}
            label="Email"
            placeholder="name@example.com"
            errors={errors}
          />

          <PhoneInputFesto
            item={'phone'}
            control={control}
            setValue={setValue}
            errors={errors}
          />
        </div>

        <InputField
          date
          name="birth_date"
          control={control}
          rules={{ required: true }}
          label="Date of Birth"
          placeholder="01 April 1998"
          errors={errors}
        />

        <div className={'terms'}>
          {termsList?.map((term, index) => <CheckBox
            term={term}
            index={index}
            list={termsList}
            setList={setTermsList}
          />)}
        </div>

        <div className={'button'}>
          <button
            type="submit"
            disabled={load || (!isEmpty(terms) && (termsList.filter(term => term?.checked)?.length !== terms?.length))}
          >
            Buy Now
            {cost !== "0" && ` - ${currency}${cost}` }
          </button>
        </div>
      </form>
    </div>
  )
}

export default ModalBuy;
