import React from "react";

import app_in_phones from "../../../assets/images/app_in_phones.png";

const HomeMeetFest = () => {
  return (
    <div className={'meet-fest-container'}>
      <div>
        <p>
          What is Festo?
        </p>

        <h3>
          Meet Festo the all-in-one solution for efficient event planning
        </h3>

        <p>
          Register your interest today and learn about next steps to set-up your event
        </p>
      </div>

      <div>
        <img src={app_in_phones}/>
        <div className={'ellipse-purple'}/>
        <div className={'ellipse-pink'}/>
      </div>
    </div>
  )
}

export default HomeMeetFest;
