import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {isArray, isEmpty} from "lodash";
import {useForm} from "react-hook-form";
import {useSnackbar} from "notistack";

import requestApiFormData from "../../../utils/requestApiFormData";
import handleImageUpload from "../../../utils/handleImageUpload";
import ShowAlert from "../../../components/ShowAlert";
import requestApi from "../../../utils/requestApi";
import InputField from "../../../components/InputField";
import formatDateAndTime from "../../../utils/formatDateAndTime";

import '../../../styles/Support.scss';

import paper from "../../../assets/svg/paper.svg";
import close_silver from "../../../assets/svg/close-silver.svg";
import attach from "../../../assets/svg/attach.svg";
import avatar from "../../../assets/svg/avatar-support.svg";
import arrowLeft from "../../../assets/svg/arrow-left.svg";

const SupportView = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const uploadButtonRef = useRef();
  const messageContainer = useRef();

  const [mobileModal, setMobileModal] = useState(false)
  const [types, setTypes] = useState([])
  const [files, setFiles] = useState([])
  const [load, setLoad] = useState(false)
  const [dataMessages, setDataMessages] = useState(null)
  const [dataRequest, setDataRequest] = useState(null)

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      message: '',
    }
  });

  const handleFileChange = (event) => {
    setFiles([...files, ...event?.target?.files].slice(0, 5));
  }

  const handleRemoveFile = (index) => {
    const newImages = [...files];
    newImages.splice(index, 1);
    setFiles(newImages);
  }

  const scrollToBottom = (ref) => {
    ref.current.scrollTop = ref.current.scrollHeight;
  };

  const onSubmit = async (data) => {
    if(load){
      setLoad(false)
      return false
    }

    setLoad(true)

    data['token'] = id

    requestApiFormData(
      '/support/add-message',
      data,
    ).then(({data: {request: {token}, messages}, status}) => {
      if(!status)
        return false;

      if(!token)
        return false;

      if(files.length && Object.keys(messages)[0])
        handleImageUpload(files, token, Object.keys(messages)[0])
          .then(() => {
            loadMessages()
            reset();
            setFiles([]);
          })
          .catch(() => ShowAlert('error', 'Unknown error!', enqueueSnackbar))
          .finally(() => setLoad(false));

      else {
        loadMessages()
        reset();
      }
    })
      .catch(() => {
        ShowAlert('error', 'Unknown error!', enqueueSnackbar);
      })
      .finally(() => setLoad(false));
  };

  const loadMessages = () => {
    requestApiFormData(
      '/support/request-messages?limit=15',
      {
        token: id,
        withFiles: true,
        limit: 50
      },
      'POST')
      .then(({data, status}) => {
        if(!status || !data?.rows){
          setDataMessages(null)
          return false;
        }

        setDataMessages(data?.rows?.reverse())
      })
      .catch(() => setDataMessages(null))
  }

  const loadRequestInfo = () => {
    requestApi.get(`/support/request-info?token=${id}`)
      .then(({data, status}) => {
        if(!status){
          setDataRequest(null)
          return false;
        }

        setDataRequest(data)
      })
      .catch(() => setDataRequest(null))
  }

  useEffect(() => scrollToBottom(messageContainer), [dataMessages])

  useEffect(() => {
    requestApi.get(`/support/list-reasons`).then(({data}) => {
      Object.keys(data)?.map((key) => types.push({ value: key, label: data[key] }))
      setTypes(types);
    });

    loadMessages();
    loadRequestInfo();
  }, [])


  return (
    <>
      <div className="register_interest support_view">
        <div className={'ellipse-container'}>
          <div className={'ellipse-first'}/>
          <div className={'ellipse-second'}/>
          <div className={'ellipse-third'}/>
        </div>

        <div className={'register-container'}>
          <div className={'information'}>
            <h1>
              {dataRequest?.subject}
            </h1>

            <div className={'support-view-information'}>
              <div className={'number'}>
                ID: {dataRequest?.id}
              </div>

              <div className={'status'}>
                <label>
                  Status
                </label>

                In progress
              </div>

              <div>
                <label>
                  Time
                </label>

                {!isEmpty(dataRequest?.created_at) && formatDateAndTime(dataRequest?.created_at)}
              </div>

              <div>
                <label>
                  Type
                </label>

                {types?.find(type => type?.value === dataRequest?.reason_id?.toString())?.label}
              </div>

              <div>
                <label>
                  Title
                </label>

                {dataRequest?.description}
              </div>
            </div>
          </div>

          <div className={'custom-form'}>
            <div
              ref={messageContainer}
              className={'messages-container'}
            >
              { !isEmpty(dataMessages)
                && isArray(dataMessages)
                && dataMessages?.map((row) => {
                    console.log('row', row)
                    const {id, message: messageObject, files} = row;
                    const {message, from_id, created_at} = messageObject;

                    return (
                      <div className={'message'} key={id}>
                        <div className={`user-container ${(from_id > 0) && 'reply'}`}>
                          <img src={avatar}/>

                          <div>
                            <p className={'name'}>
                              {(from_id > 0) ? 'Support' : 'You'}
                            </p>
                            <p className={'date'}>
                              {!isEmpty(created_at) && formatDateAndTime(created_at)}
                            </p>
                          </div>
                        </div>

                        <div className={'description'}>
                          {message}
                        </div>

                        <div className={'files'}>
                          {files?.map(file => (
                            <a
                              className={'file'}
                              href={`${process.env.REACT_APP_SPACE}${file?.link}`}
                              target={'_blank'}
                              key={file?.id}
                            >
                              <img src={attach}/>
                              <span>
                              {file?.file_name}
                            </span>
                            </a>
                          ))}
                        </div>
                      </div>
                    )
              })}
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>

              <InputField
                name="message"
                control={control}
                rules={{ required: true, minLength: 10}}
                label="Add comment"
                placeholder="...Lorem ipsum dolor sit amet, consectetur adipiscing elit..."
                errors={errors}
                textarea
              />

              {files?.length > 0 && (
                <div className='files-container'>
                  {Array.from(files)?.map((file, index) => (
                    <div className='file' key={index}>
                      <img src={paper}/>

                      <span>
                        {file?.name}
                      </span>

                      <img
                        src={close_silver}
                        onClick={() => handleRemoveFile(index)}
                      />
                    </div>
                  ))}
                </div>
              )}

              <div className={'button space-between'}>
                <div>
                  <div className='attach-button' onClick={() => uploadButtonRef?.current?.click()}>
                    <img src={attach}/>

                    <span>
                      Attach file
                    </span>
                  </div>

                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf,.mp4"
                    multiple
                    onChange={handleFileChange}
                    ref={uploadButtonRef}
                    style={{ display: "none" }}
                  />
                </div>

                <button
                  type="submit"
                  disabled={load}
                >
                  Send message
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className={'button-purple'}>
          <div
            onClick={() => setMobileModal(true)}
          >
            Start communication
          </div>
        </div>

        {mobileModal && (
          <div className={'mobile-modal'}>
            <div className={'header'}>
              <div
                className={'btn-back'}
                onClick={() => setMobileModal(false)}
              >
                <img src={arrowLeft}/>

                <span>
                  Back
                </span>
              </div>
            </div>

            <div className={'custom-form'}>
              <div
                ref={messageContainer}
                className={'messages-container'}
              >
                { !isEmpty(dataMessages)
                && isArray(dataMessages)
                && dataMessages?.map((row) => {
                  console.log('row', row)
                  const {id, message: messageObject, files} = row;
                  const {message, from_id, created_at} = messageObject;

                    return (
                      <div className={'message'} key={id}>
                        <div className={`user-container ${(from_id > 0) && 'reply'}`}>
                          <img src={avatar}/>

                          <div>
                            <p className={'name'}>
                              {(from_id > 0) ? 'Support' : 'You'}
                            </p>
                            <p className={'date'}>
                              {!isEmpty(created_at) && formatDateAndTime(created_at)}
                            </p>
                          </div>
                        </div>

                        <div className={'description'}>
                          {message}
                        </div>

                        <div className={'files'}>
                          {files?.map(file => (
                            <a
                              className={'file'}
                              href={`${process.env.REACT_APP_SPACE}${file?.link}`}
                              target={'_blank'}
                              key={file?.id}
                            >
                              <img src={attach}/>
                              <span>
                              {file?.file_name}
                            </span>
                            </a>
                          ))}
                        </div>
                      </div>
                    )
                  })}
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>

                <InputField
                  name="message"
                  control={control}
                  rules={{ required: true, minLength: 10}}
                  label="Add comment"
                  placeholder="...Lorem ipsum dolor sit amet..."
                  errors={errors}
                />

                <div className={'button space-between'}>
                  <div>
                    <div className='attach-button' onClick={() => uploadButtonRef?.current?.click()}>
                      <img src={attach}/>

                      <span>
                      Attach file
                    </span>
                    </div>

                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png,.pdf,.mp4"
                      multiple
                      onChange={handleFileChange}
                      ref={uploadButtonRef}
                      style={{ display: "none" }}
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={load}
                  >
                    Send
                  </button>
                </div>

                {files?.length > 0 && (
                  <div className='files-container'>
                    {Array.from(files)?.map((file, index) => (
                      <div className='file' key={index}>
                        <img src={paper}/>

                        <span>
                          {file?.name}
                        </span>

                        <img
                          src={close_silver}
                          onClick={() => handleRemoveFile(index)}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </form>
            </div>
          </div>
        )}

      </div>
    </>
  );
};

export default SupportView;
