import {CookieConsent} from "react-cookie-consent";
import React from "react";

const AcceptCookies = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="myAwesomeCookieName2"
      style={{
        color: '#FFF',
        fontSize: 18,
        fontWeight: '300',
        borderRadius: '12px 12px 0 0',
        background: '#212121'
      }}
      buttonStyle={{
        height: 55,
        borderRadius: 10,
        background: 'linear-gradient(176deg, #4201FE -58.26%, #9954F0 131.85%), #221F28',

        color: '#FFF',
        fontSize: 18,
        fontWeight: '600',

        paddingLeft: 50,
        paddingRight: 50
      }}
      expires={150}
    >
      We use cookies on our website to see how you interact with it. By accepting, you agree to our use of such cookies.

      {' '}

      <a href={'/privacy'} style={{
        color: '#936DFF',
        fontWeight: '500'
      }}>
        Privacy Policy
      </a>
    </CookieConsent>
  )
}

export default AcceptCookies;
