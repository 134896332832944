import React from "react";

const Topic = (
  {
    label,
    description,
    list,
    image,
    children
  }
) => {
  return (
    <div>
      {label && (<h5>
        {label}
      </h5>)}

      {children}

      {list && (<ol type="1">
        {list?.map(one => (
          <li>{one}</li>
        ))}
      </ol>)}

      {image && (<img src={image}/>)}
    </div>
  )
}

export default Topic;
