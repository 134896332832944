import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {isValidPhoneNumber} from "libphonenumber-js";
import {useSnackbar} from "notistack";
import InputField from "../../components/InputField";
import requestApiFormData from "../../utils/requestApiFormData";
import ShowAlert from "../../components/ShowAlert";

import '../../styles/RegisterInterest.scss';
import '../../styles/CustomForm.scss';

const RegisterInterest = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [load, setLoad] = useState(false)

  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      comment: '',
      company: ''
    }
  });

  const validatePhone = async (value) => {
    return isValidPhoneNumber(value);
  };

  const onSubmit = async (data) => {
    requestApiFormData(
      '/user/create-users-potential',
      data,
    )
      .then(({data, status}) => {
        if(!status){
          ShowAlert('error', 'Unknown error!', enqueueSnackbar);
          return false;
        }

        ShowAlert('success', 'Your request has been created.', enqueueSnackbar);
        reset();
      })
      .catch((err) => {
        ShowAlert('error', 'Unknown error!', enqueueSnackbar);
      })
      .finally(() => {
        setLoad(false);
      })
  };

  return (
    <>
      <div className="register_interest">
        <div className={'ellipse-container'}>
          <div className={'ellipse-first'}/>
          <div className={'ellipse-second'}/>
          <div className={'ellipse-third'}/>
        </div>

        <div className={'register-container'}>
          <div className={'information'}>
            <h1>
              Register your interest.
            </h1>

            <h2>
              Excited for Festo? Fill the form now and receive exclusive updates
            </h2>

            <p>
              Become an early supporter and receive exclusive deals directly from Festo:
            </p>

            <div className={'exclusives'}>
              <p>
                lower ticket commissions
              </p>

              <span>
                and
              </span>

              <p>
                advanced marketing
              </p>
            </div>
          </div>

          <div className={'custom-form'}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={'inputs-two'}>
                <InputField
                  name="first_name"
                  control={control}
                  rules={{ required: true }}
                  label="First name"
                  placeholder="John"
                  errors={errors}
                />

                <InputField
                  name="last_name"
                  control={control}
                  rules={{ required: true }}
                  label="Last name"
                  placeholder="Smith"
                  errors={errors}
                />
              </div>

              <div className={'inputs-two'}>
                <InputField
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Invalid email address',
                    },
                  }}
                  label="Email"
                  placeholder="name@example.com"
                  errors={errors}
                />

                <InputField
                  name="phone"
                  control={control}
                  rules={{
                    validate: validatePhone,
                  }}
                  label="Phone"
                  placeholder="+44 123 456 78 90"
                  errors={errors}
                />
              </div>

              <InputField
                name="comment"
                control={control}
                rules={{ required: true, minLength: 20 }}
                label="Leave us a message..."
                placeholder="...Lorem ipsum dolor sit amet, consectetur adipiscing elit..."
                errors={errors}
                textarea
              />

              <InputField
                name="company"
                control={control}
                rules={{ required: false }}
                label="Company (optional)"
                placeholder="Company name"
                errors={errors}
              />

              <div className={'button'}>
                <button
                  type="submit"
                  disabled={load}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterInterest;
