import React from 'react';
import Topic from "../utils/topic";

import '../../../styles/Articles.scss';

import logo_menu from "../../../assets/svg/logo_menu.svg";

const ArticleUserDeletionFacebook = () => {

  return (
    <>
      <div className="articles">
        <div className={'ellipse-container'}>
          <div className={'ellipse-first'}/>
          <div className={'ellipse-second'}/>
          <div className={'ellipse-third'}/>
        </div>

        <div className={'information'}>
          <div className={'information-container'}>
            <div className={'logo'}>
              <img src={logo_menu}/>
            </div>

            <div>
              <h1>
                User Deleting Data
              </h1>
            </div>

            <div className={'information-content'}>
              <Topic
                label={`User Deleting Data from the Mobile Application`}
                list={[
                  'Open the application and navigate to the "Account Settings" section.',
                  'Locate and select the "Delete Account" option.',
                  'Follow the on-screen instructions to confirm the deletion of your account.',
                  'Once the account deletion process is completed, all your data will be removed from our system.'
                ]}
              >
                <p>
                  Your privacy and control over your data are of paramount importance to us. If you wish to delete your data from our mobile application, you have the option to delete your account. Here's a simple guide on how to do it:
                </p>
              </Topic>

              <Topic>
                <p>
                  If you encounter any difficulties or have any questions regarding the deletion of data, please don't hesitate to contact our support team at <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>{process.env.REACT_APP_EMAIL}</a>.
                </p>
              </Topic>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleUserDeletionFacebook;
