import React, {useContext, useEffect, useRef} from "react";

import '../../styles/Modal.scss';

import iconClose from "../../assets/svg/close-silver.svg";
import {ModalContext} from "../../utils/Providers/ModalProvider";

const Modal = ({open, setOpen, title, children}) => {
  const modalRef = useRef();
  const { setModalData } = useContext(ModalContext);

  useEffect(() => {
    const handleParentClick = (e) => {
      if (modalRef.current && e.target === modalRef.current) {
        setOpen(false)
      }
    }

    document.addEventListener('click', handleParentClick);

    return () => {
      document.removeEventListener('click', handleParentClick);
    };
  }, []);

  useEffect(() => setModalData(open), [open])

  return (
    open
      ? (
        <div
          ref={modalRef}
          className={'modal'}
        >
          <div className={'modal-container'}>
            <div
              className={'close'}
              onClick={() => setOpen(false)}
            >
              <img src={iconClose}/>
            </div>

            <p>
              Registration for the event
            </p>

            <h3>
              {title}
            </h3>

            {children}
          </div>
        </div>
      )
      : null
  )
}

export default Modal;
