const customMapStyles = [
  {
    "featureType": "all",
    "elementType": "all",
    "stylers": [
      {
        "saturation": "83"
      },
      {
        "lightness": "-5"
      },
      {
        "gamma": "0.84"
      },
      {
        "weight": "0.01"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#e6e4f5"
      },
      {
        "saturation": -11
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -78
      },
      {
        "hue": "#e6e4f5"
      },
      {
        "lightness": -47
      },
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#e6e4f5"
      },
      {
        "saturation": -79
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "all",
    "stylers": [
      {
        "lightness": 30
      },
      {
        "weight": 1.3
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      },
      {
        "hue": "#e6e4f5"
      },
      {
        "saturation": -16
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -72
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -65
      },
      {
        "hue": "#e6e4f5"
      },
      {
        "lightness": 8
      }
    ]
  },
];

export default customMapStyles;
