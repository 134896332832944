import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import customMapStyles from "../../pages/Party/View/utils/customMapStyles";
import generateGoogleMapsLink from "../../utils/generateGoogleMapsLink";

import iconNavigate from "../../assets/svg/navigate.svg"

class GoogleMap extends Component {
  render() {
    const { google, positions, zoom, styles, markerIconUrl, markerImageUrl, address } = this.props;

    const mapStyles = {
      width: '100%',
      height: '450px',
    };

    return (
      <Map
        google={google}
        style={mapStyles}
        initialCenter={positions}
        zoom={zoom}
        styles={customMapStyles}
        className={'google-map'}
      >
        <Marker
          name={'point'}
          position={positions}
          icon={{
            url: markerIconUrl,
            scaledSize: new google.maps.Size(65, 65),
          }}
          zIndex={0}
        />

        <Marker
          name={'picture'}
          position={positions}
          icon={{
            url: markerImageUrl,
            scaledSize: new google.maps.Size(43, 43),
            anchor: {
              y: 57,
              x: 22
            }
          }}
          zIndex={1}
        />

        <a
          className={'btn-navigate'}
          href={generateGoogleMapsLink(address)}
          target={'_blank'}
        >
          <img src={iconNavigate}/>
          <p>
            Navigate
          </p>
        </a>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY, // Replace with your Google Maps API key
})(GoogleMap);
