const FeaturesWhiteContainer = (
  {
    reverse,
    video,
    blurVideo,
    pic,
    picRound,
    topLabel,
    title,
    description,
    icons
  }
) => {
  return (
    <div className={`white-container ${reverse ? 'reverse' : ''}`}>
      <div>
        <p>{ topLabel }</p>

        <h3>{ title }</h3>

        <p>{ description }</p>

        <div className={'icons-container'}>
          {icons?.map(({icon, text}) => (
            <div>
              <img src={ icon }/>
              <span>{ text }</span>
            </div>
          ))}
        </div>
      </div>

      <div className={`img-container ${reverse ? 'left' : ''}`}>
        {video && (<img className={'video'} src={video}/>)}
        <img src={pic} className={`${reverse ? 'left' : ''} ${picRound ? 'round' : ''}`}/>
        {blurVideo && (<div className={'video-background'}/>)}
      </div>
    </div>
  )
}

export default FeaturesWhiteContainer;
