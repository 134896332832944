import React from "react";
import {Link} from "react-router-dom";

import gifParty from "../../../assets/gif/home-party.gif"
import square_picture_2 from "../../../assets/images/square_picture_2.png";
import square_picture_3 from "../../../assets/images/square_picture_3.png";

const HomeHeaderAfter = () => {

  return (
    <div className={'after-header'}>
      <div style={{backgroundImage: `url(${gifParty})`}} >
        <div className={'after-background'}/>

        <div className={'after-info'}>
          <h5>
            Easy, fast, no fee or verification required!
          </h5>

          <p>
            Create an account with 0 fees and instantly host an event!
          </p>

          <Link
            to={process.env.REACT_APP_IOS_LINK}
            target="_blank"
          >
            See for yourself!
          </Link>
        </div>
      </div>
      <div style={{backgroundImage: `url(${square_picture_2})`}} />
      <div style={{backgroundImage: `url(${square_picture_3})`}} />
    </div>
  )
}

export default HomeHeaderAfter;
