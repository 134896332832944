import React from "react";
import {Link} from "react-router-dom";

import logo_menu from "../../../assets/svg/logo_menu.svg";
import app_store_button from "../../../assets/svg/app_store_button.svg";

const FeaturesHeader = () => {
  return (
    <div className={'header'}>
      <div className={'color'}>
        <div className={'ellipse_first'}/>
        <div className={'ellipse_second'}/>
        <div className={'ellipse_third'}/>
        <div className={'ellipse_fourth'}/>

        <div className={'information'}>
          <div className={'information-container'}>
            <div className={'logo'}>
              <img src={logo_menu}/>
            </div>

            <div>
              <h1>
                Now that's some dedication...
              </h1>
            </div>

            <div>
              <h2>
                Festo understands the inherent value of hosts to the platform and we are committed to create the best possible environment to thrive!
              </h2>
            </div>


            <Link
              to={process.env.REACT_APP_IOS_LINK}
              target="_blank"
              className={'logo_app_store'}
            >
              <img src={app_store_button}/>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturesHeader;
