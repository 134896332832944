import React from 'react';
import { Controller } from 'react-hook-form';
import Select from "react-select";
import { isEmpty } from 'lodash';
import DatePicker from 'react-datepicker';
import {selectStyles} from "./utils/selectStyles";

import "react-datepicker/dist/react-datepicker.css";

function InputField({ name, control, rules, label, errors, textarea, select, placeholder, options, className, date }) {
  return (
    <div
      className={'input-container'}
      style={className && {width: '100%'}}
    >
      {label && (
        <label>
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => textarea
          ? (
            <textarea
              {...field}
              placeholder={placeholder}
              className={!isEmpty(errors[name]) && 'input-error'}
            />
          )
          : date
            ? (
                <DatePicker
                  {...field}
                  placeholder={placeholder}
                  selected={field.value}
                />
              )
              : select
                ? (
                  <Select
                    {...field}
                    options={options}
                    className={!isEmpty(errors[name]) && 'input-error'}
                    styles={selectStyles}
                  />
                ) : (
                  <input
                    {...field}
                    placeholder={placeholder}
                    className={`${className} ${!isEmpty(errors[name]) && 'input-error'}`}
                  />
                )
        }
      />
      { errors[name] && (
        <span className={'error'}>
          { isEmpty(errors[name]?.message)
            ? (name === 'phone')
              ? `Phone is invalid`
              : `This field is required`
            : errors[name]?.message
          }
        </span>
      )}
    </div>
  );
}

export default InputField;
