import React from "react";
import generateGoogleMapsLink from "../../../utils/generateGoogleMapsLink";

import iconPartyCalendar from "../../../assets/svg/iconParty-calendar.svg";
import iconPartyTime from "../../../assets/svg/iconParty-clock.svg";
import iconPartyPrice from "../../../assets/svg/iconParty-price.svg";
import iconPartyLocation from "../../../assets/svg/iconParty-location.svg";
import iconPartyUser from "../../../assets/svg/iconParty-user.svg";

const Information = ({data}) => {
  return (
    <div className={'information'}>
      <div>
        <img src={iconPartyCalendar}/>

        <span>
          {data?.at_date}
          {(data?.at_date !== data?.to_date) && `-${data?.to_date}`}
        </span>
      </div>

      <div>
        <img src={iconPartyTime}/>

        <span>
          {data?.from_time}
          {(data?.from_time !== data?.to_time) && ` to ${data?.to_time}`}
        </span>
      </div>

      <div>
        <img src={iconPartyPrice}/>

        <span>
          {data?.price}
        </span>
      </div>

      <div
        className={'location'}
      >
        <img src={iconPartyLocation}/>

        <a
          href={`${generateGoogleMapsLink(data?.location)}`}
          target={'_blank'}
        >
          {data?.location}
        </a>
      </div>

      <div>
        <img src={iconPartyUser}/>

        <span>
          {`Age Limit: ${data?.min_age} to ${data?.max_age}`}
        </span>
      </div>
    </div>
  )
}

export default Information;
