import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';

import iconArrow from "../../assets/svg/gallery-arrow.svg"

const Gallery = ({photos}) => {
  const LeftArrow = () => {
    const { isFirstItemVisible, scrollPrev }
      = React.useContext(VisibilityContext);

    return (
      <Arrow
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}
        reverse
      />
    );
  }

  const RightArrow = () => {
    const { isLastItemVisible, scrollNext }
      = React.useContext(VisibilityContext);

    return (
      <Arrow
        disabled={isLastItemVisible}
        onClick={() => scrollNext()}
      />
    );
  }

  const Card = ({ onClick, selected, title, itemId, url }) => {
    const visibility = React.useContext(VisibilityContext);

    return (
      <div
        onClick={() => onClick(visibility)}
        tabIndex={0}
      >
        <img
          src={url}
        />
      </div>
    );
  }

  const Arrow = ({onClick, disabled, reverse}) => {
    return (
      <>
        { !disabled && (
          <div className={`gallery-arrow ${reverse && 'reverse'}`} onClick={onClick}>
            <img src={iconArrow}/>
          </div>
        )}
      </>
    )
  }

  return (
    <ScrollMenu
      LeftArrow={LeftArrow}
      RightArrow={RightArrow}
      separatorClassName={'gallery-separator'}
    >
      {photos?.map(({ id, image_url }) => (
        <Card
          itemId={id}
          title={id}
          key={id}
          url={image_url}
          onClick={() => null}
        />
      ))}
    </ScrollMenu>
  );
}

export default Gallery;
